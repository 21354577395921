'use client';

import { useLocale } from 'next-intl';

import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import IndustriousMicroLocationsProvider from '@/hooks/useIndustriousMicroLocations';

type RootLayoutProps = {
  children: React.ReactNode;
  isNavigationTransparent?: boolean;
  noContainer?: boolean;
  withFooter?: boolean;
};

export function RootLayout({
  children,
  noContainer,
  isNavigationTransparent,
  withFooter = true,
}: RootLayoutProps) {
  const locale = useLocale();

  return (
    <IndustriousMicroLocationsProvider locale={locale}>
      <Header isNavigationTransparent={isNavigationTransparent} />
      {noContainer && children}
      {!noContainer && (
        <div className="p-5 pt-7 sm:py-8 sm:px-10 sm:pt-12 xl:container">
          {children}
        </div>
      )}
      {withFooter && <Footer />}
    </IndustriousMicroLocationsProvider>
  );
}
